import _filter from "lodash/filter"

const Periods = {
  MONTHLY: "monthly",
  QUARTERLY: "quarterly",
  HALFYEARLY: "halfYearly",
  ANNUAL: "annual",
  DAILY: "daily",
}

export const getPeriodFromPlan = (interval) => {
  if (interval.unit === "DAY") {
    return Periods.DAILY
  }

  if (interval.unit === "MONTH") {
    switch (interval.length) {
      case 1:
        return Periods.MONTHLY
      case 3:
        return Periods.QUARTERLY
      case 6:
        return Periods.HALFYEARLY
      case 12:
        return Periods.ANNUAL
    }
  }

  if (interval.unit === "YEAR") {
    return Periods.ANNUAL
  }

  return ""
}

export const getPriceFromPlan = (amount) => {
  return amount / 100
}

export const getBestPlan = (code) => {
  return code === "plano_semestral"
}

export const getActivePlans = (plans) => {
  let activePlans = plans

  if (activePlans.length) {
    activePlans = activePlans.sort((a, b) => a.amount - b.amount)
    activePlans = _filter(activePlans, (plan) => plan.status === "ACTIVE")
  }

  return activePlans
}
