import { Link } from "gatsby"
import React from "react"

import translate from "../../../i18n"
import { formatNumberToCurrency } from "../../../utils/numbers"
import { getPeriodFromPlan, getPriceFromPlan } from "../../../utils/plans"

const SelectedPlanCard = ({
  selectedPlan,
  typeDiscount,
  discount,
  codeCoupon,
  switchPlanClick,
}) => {
  if (!selectedPlan) return null

  const { interval, amount } = selectedPlan

  const period = getPeriodFromPlan(interval)

  if (!codeCoupon) {
    const handleClick = (e) => {
      e.preventDefault()
      switchPlanClick()
    }

    const price = getPriceFromPlan(amount)

    return (
      <div className="flex flex-row justify-between bg-french-rose pa16 br3">
        <div className="flex flex-column justify-center items-center">
          <span className="f14 fw6 white">{translate(period)}</span>
          <span className="f20 fw6 white">{formatNumberToCurrency(price)}</span>
        </div>
        <Link
          to="#"
          className="f14 fw6 white underline mt12 pointer"
          onClick={handleClick}
        >
          {translate("switchPlan")}
        </Link>
      </div>
    )
  } else {
    const handleClick = (e) => {
      e.preventDefault()
      switchPlanClick()
    }

    if (typeDiscount === "AMOUNT") {
      const price = getPriceFromPlan(amount) - getPriceFromPlan(discount)

      return (
        <div className="flex flex-row justify-between bg-french-rose pa16 br3">
          <div className="flex flex-column justify-center items-center">
            <span className="f14 fw6 white">{translate(period)}</span>
            <span className="f20 fw6 white">
              {formatNumberToCurrency(price)}
            </span>
          </div>
          <Link
            to="#"
            className="f14 fw6 white underline mt12 pointer"
            onClick={handleClick}
          >
            {translate("switchPlan")}
          </Link>
        </div>
      )
    } else {
      const price =
        getPriceFromPlan(amount) -
        getPriceFromPlan(amount) * (getPriceFromPlan(discount) / 100)

      return (
        <div className="flex flex-row justify-between bg-french-rose pa16 br3">
          <div className="flex flex-column justify-center items-center">
            <span className="f14 fw6 white">{translate(period)}</span>
            <span className="f20 fw6 white">
              {formatNumberToCurrency(price)}
            </span>
          </div>
          <Link
            to="#"
            className="f14 fw6 white underline mt12 pointer"
            onClick={handleClick}
          >
            {translate("switchPlan")}
          </Link>
        </div>
      )
    }
  }
}

export default SelectedPlanCard
